import api from './api'

export const checkPulseAvailability = userId =>
  api.get(`v0_2/progress-pulse/${userId}/show`, {
    baseURL: process.env.NEXT_PUBLIC_CARE_API
  })

export const setPulseAnswers = (userId, data) =>
  api.post(`v0_2/progress-pulse/answers/${userId}`, data, {
    baseURL: process.env.NEXT_PUBLIC_CARE_API
  })

export const updatePulse = userId =>
  api.put(
    `v0_2/progress-pulse/${userId}/update-last-pulse`,
    {},
    {
      baseURL: process.env.NEXT_PUBLIC_CARE_API
    }
  )

export const setPulse = userId =>
  api.post(
    `v0_2/progress-pulse/${userId}`,
    {},
    {
      baseURL: process.env.NEXT_PUBLIC_CARE_API
    }
  )

export const checkCheckupAvailability = userId =>
  api.get(`v0_2/recurrent-checkup/user/${userId}/show`, {
    baseURL: process.env.NEXT_PUBLIC_CARE_API
  })

export const setCheckup = data =>
  api.post('v0_2/recurrent-checkup', data, {
    baseURL: process.env.NEXT_PUBLIC_CARE_API
  })

export const setCheckupAnswers = data =>
  api.post('v0_2/recurrent-checkup/answers', data, {
    baseURL: process.env.NEXT_PUBLIC_CARE_API
  })

export const checkCheckupBaseline = userId =>
  api.get(`v0_2/recurrent-checkup/user/${userId}/baseline`, {
    baseURL: process.env.NEXT_PUBLIC_CARE_API
  })
