import { useState, useEffect, useCallback } from 'react'

export const withBrowserPermissions = (permissions, Component) => {
  return props => {
    const [status, setStatus] = useState({})

    const hasGrantedPermission = useCallback(permission => status?.[permission] === 'granted', [
      status
    ])

    useEffect(() => {
      const queryPermissionStatus = async () => {
        const [...allStatus] = await Promise.all(
          permissions
            .map(name => ({ name }))
            .map(descriptor => navigator.permissions.query(descriptor))
        )

        const flatObject = allStatus.reduce(
          (status, result, position) => ({ ...status, [permissions[position]]: result.state }),
          {}
        )

        setStatus(flatObject)
      }

      queryPermissionStatus()
    }, [])

    return (
      <Component permissionStatus={status} hasGrantedPermission={hasGrantedPermission} {...props} />
    )
  }
}
