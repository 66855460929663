import { useFeatureFlags } from 'providers/FeatureFlagsProvider'

export const withFeatureFlagsProvider = () => {
  return Component => {
    return props => {
      const context = useFeatureFlags()

      return <Component {...props} {...context} />
    }
  }
}
