import { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import TimezoneContext from 'context/timezoneContext'
import nookies from 'nookies'

import {
  useGeolocation,
  useMutateFiltersWithAvailableQueryParameters,
  usePartnership
} from '../hooks'

import { getPaymentsInfo, getDataContractId } from '../utils/helpers'

import { getProfile } from '../services/auth'

import { loadPsychos } from '../redux/modules/psychos'
import { sendViewItemListEvent } from 'components/shared/DataLayer'

const PSYCHOLOGISTS_PER_PAGE = 6

export const withSearchOptimizations = Component => {
  return ({ query: propQuery, ...props }) => {
    const { contractId } = nookies.get() || ''
    const profile = getProfile()
    const { timezone } = useContext(TimezoneContext)

    const { query: routeQuery } = useRouter()
    const query = { ...propQuery, ...routeQuery }

    const dispatch = useDispatch()
    const { psychologists, filters, symptoms, skills } = useSelector(state => ({
      psychologists: state.psychos,
      filters: state.filters,
      symptoms: state.symptoms,
      skills: state.skills
    }))

    const [isLoadingPsychologist, setIsLoadingPsychologist] = useState(false)

    const coordinates = useGeolocation()

    const { shouldHideConsultationValue, isPresentialConsultationEnabled } = useMemo(() => {
      const entity = contractId?.integration || profile
      return {
        shouldHideConsultationValue: entity?.contract?.features?.hide_consultation_value || false,
        isPresentialConsultationEnabled:
          entity?.contract?.features?.presential_consultation_enabled || true
      }
    }, [contractId?.integration, profile])

    const filtersWithoutCoordinatesWhenConsultationIsPresential = useMemo(() => {
      const copy = JSON.parse(JSON.stringify(filters))

      switch (filters.consultation) {
        case 'online':
          delete copy.lat
          delete copy.long
          break
        case 'presencial':
        default:
          if (!filters.address) {
            copy.lat = coordinates?.latitude
            copy.long = coordinates?.longitude
            break
          }

          copy.lat = filters.lat
          copy.long = filters.long
          break
      }

      return copy
    }, [filters, coordinates?.latitude, coordinates?.longitude])

    const onSearchPsychologists = useCallback(
      params =>
        dispatch(
          loadPsychos(
            {
              ...filtersWithoutCoordinatesWhenConsultationIsPresential,
              ...params
            },
            false
          )
        ),
      [dispatch, filtersWithoutCoordinatesWhenConsultationIsPresential]
    )

    const onPaginatePsychologists = useCallback(
      () =>
        dispatch(
          loadPsychos(
            {
              ...filtersWithoutCoordinatesWhenConsultationIsPresential,
              page: psychologists?.next,
              already_appeared: (psychologists?.data || []).map(psychologist => psychologist?.id)
            },
            true
          )
        ),
      [
        dispatch,
        psychologists?.next,
        filtersWithoutCoordinatesWhenConsultationIsPresential,
        psychologists?.data
      ]
    )

    useMutateFiltersWithAvailableQueryParameters(filters, {
      ...query,
      lat: coordinates?.latitude,
      long: coordinates?.longitude,
      timezone: timezone || 'America/Sao_Paulo',
      contract_id:
        contractId ||
        getDataContractId()?.integration?.contract?.id ||
        query?.contract_id ||
        profile?.contract?.id ||
        ''
    })
    usePartnership(query.partnership)

    useEffect(() => {
      getPaymentsInfo(timezone)
    }, [timezone])

    useEffect(() => {
      if (!dataLayer) {
        return
      }

      const impressions = (psychologists.data || [])
        .slice(psychologists.data?.length - PSYCHOLOGISTS_PER_PAGE)
        .reduce((impressions, psychologist, position) => {
          const schedule = psychologist?.schedules?.find(schedule =>
            query?.consultation === 'presencial' ? schedule.prof_address : !schedule.prof_address
          )
          return [
            ...impressions,
            {
              name: psychologist?.name,
              id: psychologist?.id,
              price: schedule?.price?.value,
              list: 'Search results',
              position:
                psychologists?.data?.length <= PSYCHOLOGISTS_PER_PAGE
                  ? position
                  : psychologists?.page * PSYCHOLOGISTS_PER_PAGE + position
            }
          ]
        }, [])

      sendViewItemListEvent(impressions)
    }, [psychologists?.data, psychologists?.page, query?.consultation])

    return (
      <Component
        query={query}
        isLoadingPsychologist={isLoadingPsychologist}
        psychologists={psychologists}
        filters={filters}
        symptoms={symptoms}
        skills={skills}
        shouldHideConsultationValue={shouldHideConsultationValue}
        isLoadingPsychologists={psychologists.loading}
        hasMorePsychologistsToLoad={!!psychologists?.next}
        isPresentialConsultationEnabled={isPresentialConsultationEnabled}
        timezone={timezone}
        shouldSpecifyLocationOnMoreFilters={
          !coordinates && [filters.consultation, query.consultation].includes('presencial')
        }
        onNavigateToPsychologist={() => setIsLoadingPsychologist(true)}
        onSearchPsychologists={onSearchPsychologists}
        onPaginatePsychologists={onPaginatePsychologists}
        {...props}
      />
    )
  }
}
