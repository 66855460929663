import React from 'react'
import Router, { withRouter } from 'next/router'
import Link from 'next/link'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setCookie } from 'nookies/dist'

import Wrapper from '../components/Wrapper'
import FooterIntercomClickableHome from '../components/shared/FooterStatic/FooterIntercomClickableHome'
import { validate as validatePatientLogin } from '../components/SouPaciente/validateLogin'
import { validate as validatePsychoLogin } from '../components/SouPsicologo/Login/validate'
import { BackArrowAlternative, BlueProfileIcon, Warning } from '../components/v2/Icons'
import { Captcha } from '../components/v2/Captcha'
import { HeaderRendering } from '../components/v2/Header'
import { Button } from '../components/v2/Button'
import { Column, Row } from 'components/v2/Grid'

import { loginPat, loginPsycho } from '../redux/modules/auth'
import { getWhatsUrl } from '../services/userService'
import { addPartnership } from '../services/userService'
import { getIsSsoUserByCpf, getProfile, getUser, setProfile } from '../services/auth'
import { applyPreSignUpContract, getActivationB2b } from 'services/preSignUp'
import { getDataContractId, navHeight } from '../utils/helpers'
import { normalizeCPF, normalizeDocument } from '../utils/normalizers'
import { redirectToSaneparSSO } from '../utils/sso'

import get_in from '../styles/get-in.scss'
import sched_pay from '../styles/sched_pay.scss'
import { toast } from 'react-toastify'

import { withFeatureFlagsProvider } from 'hocs'
import {
  checkCheckupAvailability,
  checkCheckupBaseline,
  checkPulseAvailability,
  setCheckup,
  setPulse
} from 'services/forms'

const metaTags = {
  title: 'Paciente Vittude - Entrar',
  description:
    'Encontre um psicólogo que combina com você!! Agende uma consulta com um psicólogo em seu bairro ou escolha um psicólogo online.',
  hreflang: 'https://vittude.com/entrar'
}

const RegisterForm = reduxForm({
  form: 'register'
})(({ handleSubmit, onSubmit, ...props }) => {
  const { active, swap } = props

  return (
    <form className='card-form' onSubmit={handleSubmit(onSubmit(active))}>
      <div className='form'>
        <div className='top-tabs'>
          <div className='tabs-container register'>
            <div className='tab' onClick={() => swap(0)}>
              <div className={`${active === 0 ? 'active' : ''}`}>
                <img src='/static/images/psico-azul.svg' alt='' />
                <span>Psicólogo</span>
              </div>
            </div>
            <div className='tab' onClick={() => swap(1)}>
              <div className={`${active === 1 ? 'active' : ''}`}>
                <BlueProfileIcon />
                <span>Paciente</span>
              </div>
            </div>
          </div>
        </div>
        <div className='form-section register'>
          <label htmlFor='email' style={{ color: '#27A5E0' }}>
            Email*
          </label>
          <Field
            component='input'
            id='email'
            type='email'
            name='email'
            placeholder='Digite seu email'
          />
        </div>
        <div className='form-section register'>
          <label style={{ color: '#27A5E0' }} htmlFor='cpf'>
            CPF*
          </label>
          <Field
            id='cpf'
            component='input'
            type='text'
            name='cpf'
            normalize={active === 1 ? normalizeCPF : normalizeDocument}
            placeholder='999.999.999-99'
          />
        </div>
        <div className='form-section form-section--checkbox register'>
          <Field
            component='input'
            type='checkbox'
            name='receive_notifications'
            id='receive_notifications'
          />
          <label className='labelNews' htmlFor='receive_notifications'>
            Opa! Quero sim receber novidades no meu email, sim!
          </label>
        </div>
      </div>
      <button
        className={'button-submit button-submit--blue'}
        style={{ marginTop: '1rem', borderRadius: '4px' }}
        type='submit'
      >
        <span>Criar Conta</span>
      </button>
    </form>
  )
})

const LoginForm = reduxForm({
  form: 'login'
})(
  class extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        caps: false,
        shift: false,
        hidden: true,
        cpnj: '',
        email: '',
        password: ''
      }

      this.handlePasswordChange = this.handlePasswordChange.bind(this)
      this.toggleShow = this.toggleShow.bind(this)
    }

    handlePasswordChange(e) {
      this.setState({ password: e.target.value })
    }

    toggleShow() {
      this.setState({ hidden: !this.state.hidden })
    }

    componentDidMount() {
      if (this.props.password) {
        this.setState({ password: this.props.password })
      }
    }

    render() {
      const {
        handleSubmit,
        onSubmit,
        swap,
        active,
        isCaptchaShowed,
        handleCaptcha,
        loader
      } = this.props

      return (
        <form className='card-form' onSubmit={handleSubmit(onSubmit(active))}>
          <div className='form'>
            <div className='top-tabs'>
              <div className='tabs-container'>
                <div className='tab' onClick={() => swap(0)}>
                  <div className={`${active === 0 ? 'active' : ''}`}>
                    <img src='/static/images/psico-orange.svg' alt='' />
                    <span>Psicólogo</span>
                  </div>
                </div>
                <div className='tab' onClick={() => swap(1)}>
                  <div className={`${active === 1 ? 'active' : ''}`}>
                    <img src='/static/images/paciente-terracota.svg' alt='' />
                    <span>Paciente</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='form-content'>
              {active === 1 ? (
                <div className='form-section'>
                  <label htmlFor='email'>Email*</label>
                  <Field
                    id='email'
                    component='input'
                    type='email'
                    name='username'
                    placeholder='Digite seu email'
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </div>
              ) : (
                <div className='form-section'>
                  <label htmlFor='cpf_cnpj'>CPF/CNPJ*</label>
                  <Field
                    component='input'
                    type='text'
                    name='cpf_cnpj'
                    normalize={normalizeDocument}
                    placeholder='999.999.999-99'
                    value={this.state.cnpj}
                    onChange={e => this.setState({ cnpj: e.target.value })}
                  />
                </div>
              )}
              <div className='form-section'>
                <label htmlFor='password'>Senha*</label>
                <div className='visible' onClick={this.toggleShow}>
                  {this.state.hidden ? (
                    <span>
                      <img src='/static/images/show.png' alt='' />
                    </span>
                  ) : (
                    <span>
                      <img src='/static/images/hide.png' alt='' />
                    </span>
                  )}
                </div>
                <Field
                  component='input'
                  type={this.state.hidden ? 'password' : 'text'}
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  name='password'
                  id='password'
                  placeholder='Digite sua senha'
                  onKeyUp={event => {
                    if (event.key === 'Shift') {
                      this.setState({ shift: false })
                    }
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Shift') {
                      this.setState({ shift: true })
                    } else {
                      if (
                        event.key === event.key.toUpperCase() &&
                        event.key >= 'A' &&
                        event.key <= 'Z' &&
                        !this.state.shift
                      ) {
                        this.setState({ caps: true })
                      } else {
                        this.setState({ caps: false })
                      }
                    }
                  }}
                />

                {!this.state.shift && this.state.caps && (
                  <small className='text-left aviso_capslock'>Seu CAPS LOCK está ligado.</small>
                )}
              </div>
            </div>

            <div className='d-flex justify-content-between'>
              <Link
                href={active === 1 ? '/patient-reset_password' : '/psicologo-reset_password'}
                as={active === 1 ? '/patient/reset_password' : '/psicologo/reset_password'}
              >
                <a className='text-left linkPassword ml-auto'>Esqueci minha senha!</a>
              </Link>
            </div>
            <Captcha onChange={() => handleCaptcha()} isCaptchaShowed={isCaptchaShowed} />
          </div>
          <Button
            variant='primary'
            borderColor='primary'
            height={['46px', '58px']}
            fontWeight='600'
            fontSize='1.1em'
            letterSpacing='1px'
            mt='16px'
            type='submit'
            loading={loader}
          >
            Entrar
          </Button>
          {active === 1 && (
            <Button
              variant='white-outlined-gray'
              heigh
              t={['46px', '58px']}
              fontWeight='600'
              fontSize='1.1em'
              letterSpacing='1px'
              mt='16px'
              gridGap='12px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              onClick={() => redirectToSaneparSSO()}
            >
              Acesso Sanepar (SSO)
            </Button>
          )}
        </form>
      )
    }
  }
)

class GetIn extends React.Component {
  notification = null
  state = {
    loginForm: true,
    active: 1,
    loader: false,
    isCaptchaShowed: false,
    isCaptchaSelected: false,
    contractId: null,
    presignupToken: null
  }

  static getInitialProps({ query }) {
    return { query }
  }

  handleCaptcha = () => {
    this.setState({
      isCaptchaSelected: !this.state.isCaptchaSelected
    })
  }

  handleChange = () => {
    this.setState({
      loginForm: !this.state.loginForm
    })
  }

  componentDidMount() {
    const dataContractId = getDataContractId()
    const ignoreApply = localStorage.getItem('ignoreApply')

    if (!ignoreApply && dataContractId) {
      const contractId = dataContractId?.integration?.contract?.id
      const presignupToken = dataContractId?.token

      this.setState({ contractId, presignupToken })
    }

    localStorage.removeItem('ignoreApply')
    this.notification = this.refs.getIn
  }

  register = type => async values => {
    if (values.email && values.cpf) {
      const query = {
        email: values.email,
        cpf: values.cpf,
        receive_notifications: values.receive_notifications,
        ...(this.state.presignupToken && { presignup_token: this.state.presignupToken }),
        ...(this.state.contractId && { contract_id: this.state.contractId })
      }

      if (type === 0) {
        return Router.push({
          pathname: '/sou-psicologo-signup',
          asPath: '/sou-psicologo-signup',
          query
        })
      }

      try {
        const { data: response } = await getIsSsoUserByCpf(values.cpf)
        if (response.code === 200) {
          const isSaneparUser = response.log_content?.code === 'is_sanepar_user'

          if (isSaneparUser) {
            return redirectToSaneparSSO()
          }
          return Router.push({
            pathname: '/sou-paciente',
            asPath: '/sou-paciente',
            query
          })
        }
      } catch (error) {
        console.log(error)
        toast.error('Ocorreu um errro! Tente novamente ou entre em contato com o suporte.', {
          autoClose: 5000
        })
      }
    }
  }

  whatsUrl = (patient_id, psico_id /* eslint-disable-line */) => {
    getWhatsUrl(patient_id, psico_id)
      .then(res => {
        window.location.href = res.content.whats_url
      })
      .catch(error => {
        console.log(error)
      })
  }

  login = type => values => {
    const hasContractId = getDataContractId()

    this.setState({ loader: true })

    if (hasContractId) {
      localStorage.removeItem('dataContractId')
    }

    if (this.state.isCaptchaShowed && !this.state.isCaptchaSelected) {
      this.setState({ loader: false })

      toast.warning('Por questões de segurança, responda ao captcha para continuar o processo', {
        autoClose: 5000
      })
      return
    }
    if (this.state.isCaptchaShowed && this.state.isCaptchaSelected) {
      this.setState({
        isCaptchaShowed: false,
        isCaptchaSelected: false
      })
    }
    if (type === 0) {
      const errors = validatePsychoLogin({ credentials: values })
      if (errors.required) {
        this.setState({
          loader: false
        })
        toast.warning(`${errors.required}`, { autoClose: 5000 })
      } else if (errors.general.length > 0) {
        this.setState({
          loader: false
        })
        toast.warning(`${errors.general.map(msg => `${msg}\n`).join('')}`, { autoClose: 5000 })
      } else {
        this.props
          .loginPsycho(values)
          .then(async () => {
            const user = getProfile()
            await this.props.getFeatures()
            user.subscription_active === false
              ? Router.push({
                  pathname: '/painel',
                  query: { menu: 'painel-financeiro' },
                  asPath: '/painel/financeiro'
                })
              : Router.push({
                  pathname: '/painel',
                  query: { menu: 'meus-dados' },
                  asPath: '/painel/agendas'
                })
          })
          .catch(error => {
            console.log(error)
            this.setState({ loader: false })
            if (error === 'precondition_required') {
              toast.error('Sua conta está inativa. Entre em contato com nosso suporte.', {
                autoClose: 5000
              })
            } else {
              if (error.response?.status === 429) {
                toast.warning(
                  `Quantidade máxima de requisições atingida. Aguarde ao menos ${error.response?.data?.availableIn} e tente novamente.`,
                  { autoClose: 5000 }
                )

                this.setState({ isCaptchaShowed: true })
                return
              }

              toast.error('Dados de usuário incorretos', { autoClose: 5000 })
            }
          })
      }
    } else {
      const errors = validatePatientLogin({ credentials: values })

      const { partnership } = this.props

      if (partnership) {
        if (this.props.user.id) {
          addPartnership(partnership, this.props.user.id)
        }
      }
      if (errors.required) {
        this.setState({ loader: false })
        toast.warning(`${errors.required}`, { autoClose: 5000 })
      } else if (errors.general.length > 0) {
        this.setState({ loader: false })
        toast.warning(`${errors.general.map(msg => `${msg}\n`).join('')}`, { autoClose: 5000 })
      } else {
        this.props
          .loginPat(values)
          .then(async response => {
            const { query } = this.props.router
            const { data } = await this.props.getFeatures()

            if (response?.data?.content?.user?.contract?.id) {
              setCookie(null, 'contractId', response.data.content.user.contract.id, {
                maxAge: 24 * 60 * 60, // 1 day
                path: '/'
              })
            }

            if (this.state.contractId && this.state.presignupToken) {
              const {
                data: {
                  content: { has_active_contract }
                }
              } = await getActivationB2b(`email=${values.username}`)

              const hasActiveContract =
                has_active_contract && response?.data?.content?.user?.contract?.id

              const preSignUpData = {
                patient_id: response.data.content.user.id,
                presignup_token: encodeURIComponent(this.state.presignupToken)
              }

              await applyPreSignUpContract(preSignUpData)

              const res = await getUser()

              setProfile(res.data.content.user)

              if (hasActiveContract) {
                Router.push(`/busca?contractId=${this.state.contractId}`)
                return
              }
            }

            if (response?.data?.content?.user?.contract?.id) {
              if (data?.frontend_psychologist_pulse_feature) {
                const pulseResponse = await checkPulseAvailability(
                  response?.data?.content?.user?.id
                )

                if (pulseResponse?.data?.show) {
                  await setPulse(response?.data?.content?.user?.id)

                  Router.push(`/forms/pulso?firstAccess=${pulseResponse?.data?.is_first_pulse}`)
                  return
                }
              }

              if (data?.frontend_checkup_form_feature) {
                const checkup = await checkCheckupAvailability(response?.data?.content?.user?.id)
                let checkupBaseLineAvailable = false

                try {
                  const checkupBaseline = await checkCheckupBaseline(
                    response?.data?.content?.user?.id
                  )

                  if (checkupBaseline) {
                    checkupBaseLineAvailable = checkupBaseline?.data?.is_baseline
                  }
                } catch (err) {
                  checkupBaseLineAvailable = false
                }

                if (checkup?.data?.show_form) {
                  const checkupObject = await setCheckup({
                    user_id: response?.data?.content?.user?.id,
                    contract_id: response?.data?.content?.user?.contract?.id
                  })

                  Router.push(
                    `/forms/checkup?forms=${checkup?.data?.forms.join(
                      ','
                    )}&recurrent=${checkupBaseLineAvailable}&recurrentCheckupId=${
                      checkupObject?.data?.id
                    }`
                  )

                  return
                }
              }
            }

            if (
              typeof query !== undefined && // eslint-disable-line
              query?.whats
            ) {
              const user = getProfile()
              var newUrl = new URL('http://localhost:3000/' + query)
              const psico_id = newUrl.searchParams.get('psico_id') // eslint-disable-line
              this.whatsUrl(user.id, psico_id)
            }

            if (query?.checkoutId && query?.timezone) {
              Router.push(
                `/agendar-consultas/finalizar-pagamento?checkoutId=${query?.checkoutId}&timezone=${query?.timezone}`
              )
              return
            }

            if (query?.rmkt) {
              Router.push({
                pathname: '/agendamento',
                as: '/agendamento'
              })
              return
            }

            if (query?.redirectTo) {
              Router.push({
                pathname: '/redirect',
                query: {
                  destination: query?.redirectTo,
                  external: query?.external
                }
              })
              return
            }
            if (query?.referrer) {
              return window.location.assign(`http://${query?.referrer}`)
            }
            Router.push({
              pathname: '/painel',
              query: { menu: 'minhas-consultas' },
              as: '/painel/minhas-consultas'
            })
          })
          .catch(err => {
            this.setState({ loader: false })

            if (err?.response?.status === 429) {
              toast.warning(
                `Quantidade máxima de requisições atingida. Aguarde ao menos ${err.response?.data?.availableIn} e tente novamente.`,
                { autoClose: 5000 }
              )
              this.setState({ isCaptchaShowed: true })
              return
            }
            toast.error('Dados de usuário incorretos', { autoClose: 5000 })
          })
      }
    }
  }

  swap = active => {
    this.setState({ active })
  }

  render() {
    const { router } = this.props

    return (
      <Wrapper noIndex metaTags={metaTags} cssFile={[get_in, sched_pay]}>
        <HeaderRendering />
        <div style={{ marginTop: navHeight() }}>
          <section className='header-decoration'> </section>
          <section id='getIn'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-md-7 no-padding-mobile mx-auto'>
                  <h3 className='title title--black'>
                    {this.state.loginForm ? 'Faça seu Login' : 'Cadastre-se agora!'}
                  </h3>
                  {this.state.loginForm ? (
                    <LoginForm
                      onSubmit={this.login}
                      swap={this.swap}
                      active={this.state.active}
                      isCaptchaShowed={this.state.isCaptchaShowed}
                      isCaptchaSelected={this.state.isCaptchaSelected}
                      handleCaptcha={this.handleCaptcha}
                      loader={this.state.loader}
                      initialValues={{
                        email: router?.query?.email
                      }}
                    />
                  ) : (
                    <RegisterForm
                      onSubmit={this.register}
                      swap={this.swap}
                      active={this.state.active}
                      loader={this.state.loader}
                    />
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6 no-padding-mobile mx-auto'>
                  <h6 className='question question--black'>
                    {this.state.loginForm ? (
                      <>
                        Não é cadastrado?
                        <button onClick={this.handleChange}> Crie uma conta </button>
                      </>
                    ) : (
                      <>
                        Já é cadastrado?
                        <button onClick={this.handleChange} className='register'>
                          Faça seu login
                        </button>
                      </>
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterIntercomClickableHome />
      </Wrapper>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginPat,
      loginPsycho
    },
    dispatch
  )
export default connect(null, mapDispatchToProps)(withRouter(withFeatureFlagsProvider()(GetIn)))
