const requiredMessage = name => 'Os campos com * são obrigatórios'

export const validate = values => {
  const errors = {
    required: '',
    general: []
  }

  if (!values.credentials) {
    errors.required = requiredMessage()
    return errors
  }

  if (!values.credentials.username) {
    errors.required = requiredMessage()
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(values.credentials.username)) {
    errors.general.push('Formato de e-mail inválido!')
  }

  if (!values.credentials.password) {
    errors.required = requiredMessage()
  }

  return errors
}
