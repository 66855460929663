import { useFeatureFlags } from 'hooks'

export const withFeatureFlags = (...flags) => {
  return Component => {
    return props => {
      const features = useFeatureFlags(...flags)

      return <Component features={features} {...props} />
    }
  }
}
