import { useMutation } from '@tanstack/react-query'

import api from './api'

import { useValidate } from 'services/journey-unification'

export const preSignUp = values => api.post('/presignup/v2/match/', values)

export const getActivationB2b = values => api.get(`/patient/activation_details/?${values}`)

export const getInputsByContract = contract_id =>
  api.get(`/custom_forms/inputs/contract/${contract_id}`)

export const applyPreSignUpContract = data =>
  api.post(`/presignup/apply/${data.presignup_token}/patient/${data.patient_id}`)

export const getPreSignUpSecondCheck = async () => api.get('/presignup/second-check/')

export const applyPreSignupSecondCheck = async data => api.post('/presignup/second-check/', data)

export const deletePreSignUp = async () => api.delete('/presignup/second-check/')

export const useMatchPreSignup = options => useMutation(preSignUp, options)

export const useActivatePreSignup = options => useMutation(applyPreSignUpContract, options)

export const useValidateAndActivatePreSignup = (patientId, options) => {
  const { mutateAsync: validateAsync } = useValidate()
  const { mutateAsync: activateAsync } = useActivatePreSignup()

  return useMutation(async data => {
    const presignup = await validateAsync({
      company_id: data?.company_id,
      key_type: parseInt(data?.key_type),
      access_key: data?.access_key
    })

    if (patientId) {
      await activateAsync({
        patient_id: patientId,
        presignup_token: presignup?.token
      })
    }

    return presignup
  }, options)
}
