import { useQuery, useMutation } from '@tanstack/react-query'

import client from 'services/api'

export const getJourneyUnifications = async () => {
  const response = await client.get('/b2b2c/company-benefits/')
  return response.data?.data?.forms || []
}

export const validate = async (data = {}) => {
  const response = await client.post('/b2b2c/company-benefits/', data)
  return response.data?.pre_signups?.[0]
}

export const useJourneyUnifications = (options = {}) =>
  useQuery(['get-journey-unifications'], () => getJourneyUnifications(), options)

export const useValidate = options => useMutation(validate, options)
