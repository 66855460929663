import React from 'react'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'

const Captcha = ({ onChange, isCaptchaShowed }) => {
  return (
    isCaptchaShowed && <ReCaptcha sitekey={process.env.CAPTCHA_KEY} onChange={() => onChange()} />
  )
}

const ReCaptcha = styled(ReCAPTCHA)`
  transform: scale(0.87);
  -webkit-transform: scale(0.87);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
`

export { Captcha }
