import Router from 'next/router'

export const redirectToSaneparSSO = customRedirectPath => {
  const authorizationURI = `${process.env.NEXT_PUBLIC_SSO_SANEPAR_API}/auth`
  const clientId = 'vittude'
  const redirectUri = `${window.location.origin}${customRedirectPath || '/sso-redirect'}`
  const authUrl = `${authorizationURI}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&response_type=code&scope=openid`

  Router.push(authUrl)
}
